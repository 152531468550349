#front-logo {
    width: 300px !important;
    height: 60px !important;
}

/*koszyk*/
@media (min-width: 1025px) {
    .h-custom {
        height: 100vh !important;
    }
}@media (max-width: 768px) {
    #add_to_cart_quantity, .koszykowy_button {
        min-width: 4rem !important;
    }
}

.number-input input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

.number-input input[type=number]::-webkit-inner-spin-button,
.number-input input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.number-input button {
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0;
    position: relative;
}

.number-input button:before,
.number-input button:after {
    display: inline-block;
    position: absolute;
    content: '';
    height: 2px;
    transform: translate(-50%, -50%);
}

.number-input button.plus:after {
    transform: translate(-50%, -50%) rotate(90deg);
}

.number-input input[type=number] {
    text-align: center;
}

.number-input.number-input {
    border: 1px solid #ced4da;
    width: 10rem;
    border-radius: .25rem;
}

.number-input.number-input button {
    width: 2.6rem;
    height: .7rem;
}

.number-input.number-input button.minus {
    padding-left: 10px;
}

.number-input.number-input button:before,
.number-input.number-input button:after {
    width: .7rem;
    background-color: #495057;
}

.number-input.number-input input[type=number] {
    max-width: 4rem;
    padding: .5rem;
    border: 1px solid #ced4da;
    border-width: 0 1px;
    font-size: 1rem;
    height: 2rem;
    color: #495057;
}

@media not all and (min-resolution:.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color:transparent) {

        .number-input.def-number-input.safari_only button:before,
        .number-input.def-number-input.safari_only button:after {
            margin-top: -.3rem;
        }
    }
}

.shopping-cart .def-number-input.number-input {
    border: none;
}

.shopping-cart .def-number-input.number-input input[type=number] {
    max-width: 2rem;
    border: none;
}

.shopping-cart .def-number-input.number-input input[type=number].black-text,
.shopping-cart .def-number-input.number-input input.btn.btn-link[type=number],
.shopping-cart .def-number-input.number-input input.md-toast-close-button[type=number]:hover,
.shopping-cart .def-number-input.number-input input.md-toast-close-button[type=number]:focus {
    color: #212529 !important;
}

.shopping-cart .def-number-input.number-input button {
    width: 1rem;
}

.shopping-cart .def-number-input.number-input button:before,
.shopping-cart .def-number-input.number-input button:after {
    width: .5rem;
}

.shopping-cart .def-number-input.number-input button.minus:before,
.shopping-cart .def-number-input.number-input button.minus:after {
    background-color: #9e9e9e;
}
.text-primary{
    color:#59ab6e !important;
}
div.d-flex a{
    color: #59ab6e;
}
.card-body button.btn-primary{
    background-color: #59ab6e !important;
    border-color: #59ab6e !important;
}
#add_to_cart_quantity, .koszykowy_button{
    max-width: 5rem;
    min-width: 4rem;
    text-align: center;
}

strong.koszykowa_ilosc{
    margin-top: 7px;
    margin-right: 7px;
}
.delete_cart_item{
    border-color: #59ab6e;
    color: #59ab6e;
}
.delete_cart_item:before {
    content: "\f00d" !important;
}
.alert-info, .alert-info a{
    background-color: #59ab6e;
    border-color: #212934;
    color: #212934 !important;
}
img.rounded-circle{
    display: block;
    margin: 0 auto;
}


/*strona w budowie*/
.construction-bar {
    background-color: #e0f2f1; /* Kolor seledynowego tła */
    height: 1.5rem; /* Wysokość sekcji - dostosuj wartość według potrzeb */
}

.construction-message-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.construction-message {
    text-align: center;
    font-weight: bold;
    font-size: 16px; /* Rozmiar czcionki - dostosuj wartość według potrzeb */
    margin: 0;
    padding: 0;
}

